import BackendService from './BackendService';

export default class TPMService {
    // static getUser() {
    //     return BackendService.get("/sso/user").then(resp => {
    //         console.log(resp)
    //         UserService.user = resp.data.toLowerCase()
    //     })
    // }
    static getForms() {

        return BackendService.get("/tpm/forms").then(resp => {
            console.log(resp)
            return resp.data
        })
    }

    static getForm(id) {

        return BackendService.get("/tpm/form?id=" + id).then(resp => {
            console.log(resp)
            return resp.data
        })
    }

    static newForm(responsible, company) {
        var payload = {
            "responsible": responsible,
            "company": company,
        }
        return BackendService.post("/tpm/new-form", payload).then(resp => {
            console.log(resp)
            return resp.data
        })
    }

    static fillForm(ID, OperationalImpact1Hour, OperationalImpact6Hour, OperationalImpact12Hour, ReputationalImpact1Hour, ReputationalImpact6Hour, ReputationalImpact12Hour, RegulatoryImpact1Hour, RegulatoryImpact6Hour, RegulatoryImpact12Hour, FinancialImpact1Hour, FinancialImpact6Hour, FinancialImpact12Hour, Workaround, WorkaroundText, LossOfConfidentialityImpact, LossOfConfidentialityProbability, LossOfIntegrityImpact, LossOfIntegrityProbability, LossOfDisponibilityImpact, LossOfDisponibilityProbability) {
        console.log(ID, OperationalImpact1Hour, OperationalImpact6Hour, OperationalImpact12Hour, ReputationalImpact1Hour, ReputationalImpact6Hour, ReputationalImpact12Hour, RegulatoryImpact1Hour, RegulatoryImpact6Hour, RegulatoryImpact12Hour, FinancialImpact1Hour, FinancialImpact6Hour, FinancialImpact12Hour, Workaround, WorkaroundText, LossOfConfidentialityImpact, LossOfConfidentialityProbability, LossOfIntegrityImpact, LossOfIntegrityProbability, LossOfDisponibilityImpact, LossOfDisponibilityProbability)
        var payload = {
            "id": ID,
            "operationalImpact1Hour": OperationalImpact1Hour,
            "operationalImpact6Hour": OperationalImpact6Hour,
            "operationalImpact12Hour": OperationalImpact12Hour,
            "reputationalImpact1Hour": ReputationalImpact1Hour,
            "reputationalImpact6Hour": ReputationalImpact6Hour,
            "reputationalImpact12Hour": ReputationalImpact12Hour,
            "regulatoryImpact1Hour": RegulatoryImpact1Hour,
            "regulatoryImpact6Hour": RegulatoryImpact6Hour,
            "regulatoryImpact12Hour": RegulatoryImpact12Hour,
            "financialImpact1Hour": FinancialImpact1Hour,
            "financialImpact6Hour": FinancialImpact6Hour,
            "financialImpact12Hour": FinancialImpact12Hour,
            "workaround": Workaround,
            "workaroundText": WorkaroundText,
            "lossOfConfidentialityImpact": LossOfConfidentialityImpact,
            "lossOfConfidentialityProbability": LossOfConfidentialityProbability,
            "lossOfIntegrityImpact": LossOfIntegrityImpact,
            "lossOfIntegrityProbability": LossOfIntegrityProbability,
            "lossOfDisponibilityImpact": LossOfDisponibilityImpact,
            "lossOfDisponibilityProbability": LossOfDisponibilityProbability
        }
        return BackendService.post("/tpm/fill-form", payload).then(resp => {
            console.log(resp)
            return resp.data
        })
    }
}
