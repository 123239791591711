<template>
  <div class="grid">
    <Toast />
    <Sidebar
      v-model:visible="showInfoConfidentiality"
      :baseZIndex="10000"
      position="right"
      style="width: 500px"
    >
      <h3>Baixo</h3>
      Para uma perda de confidencialidade de baixo impacto, considera-se que as
      informações tem uma relevância médio caso sejam divulgadas. Os impactos
      financeiro, reputacional, operacional e regulatórios relacionados são
      baixos.
      <br />
      <br />
      Exemplos de informações com baixa confidencialidade:
      <br />
      • Relatórios anuais, após sua publicação;<br />
      • Brochuras de cliente;<br />
      • Áreas públicas do site do BTG Pactual;<br />
      • Informações liberadas para publicação.<br />
      • Diretrizes, políticas e manuais;<br />
      • Grande parte do conteúdo da Intranet, incluindo organogramas;<br />
      • Descrições de produtos e processos;<br /><br />

      <h3>Médio</h3>
      Para uma perda de confidencialidade de médio impacto, considera-se que as
      informações tem uma relevância médio caso sejam divulgadas. Os impactos
      financeiro, reputacional, operacional e regulatórios relacionados são
      médios.
      <br /><br />
      Exemplos de informações com média confidencialidade:
      <br />

      • Descrições de produtos e processos;<br />
      • Declarações de ativos;<br />
      • Registros de crédito;<br />
      • No âmbito da área de negócio e acima: documentos de planejamento e
      relatório, incluindo orçamentos e relatórios financeiros;<br />
      • Documentos contábeis;<br />
      • Documentos de gestão;<br />
      • Dados importantes relacionados às análises de lucros e prejuízos;<br />
      • Revisão da realização de objetivos. • Informações relacionadas a
      projetos que envolvem produtos de terceiros;<br />
      • Resultados de inspeções ou auditorias;<br />
      • Dados de abertura de mercado para corretoras;<br />
      • Números e declarações de contas/contas de custódia;<br />
      • Dados financeiros para as instituições reguladoras (BACEN, CVM etc.);<br />
      • Relatórios de Research (Macro Research, etc.);<br />
      • Comunicação com empresas do grupo BTG ou parceiros.<br /><br />

      <h3>Alto</h3>
      Para uma perda de confidencialidade de alta impacto, considera-se que as
      informações tem uma relevância alta caso sejam divulgadas. Os impactos
      financeiro, reputacional, operacional e regulatórios relacionados são
      alta.
      <br /><br />
      Exemplos de informações com alta confidencialidade:
      <br />
      • transações importantes;<br />
      • fusões e aquisições;<br />
      • novos produtos;<br />
      • mudanças estratégicas;<br />
      • segredos comerciais ou propriedade intelectual;<br />
      • informações protegidas por cláusulas;<br />
      • contratos de confidencialidade;<br />
      • Declarações relacionadas às reservas/amortizações/provisões;<br />
      • Importantes projetos do Comitê de Gestão.<br />
      • Informações de clientes e decolaboradores (Informações Pessoalmente
      Identificáveis - PII (na sigla eminglês)), incluindo nomes, endereços,
      números de identificação nacionais, cartões de crédito, data de
      nascimento, dados financeiros etc.;<br />
      • Documentos sobre salários, bônus e promoção;<br />
      • Transações individuais com nomes de clientes;<br />
      • Documentos assinados de clientes, com contas nomeadas;<br />
      e Arquivos de RH / dossiê único de RH.
    </Sidebar>
    <Sidebar
      v-model:visible="showInfoIntegrity"
      :baseZIndex="10000"
      position="right"
    >
      <h3>Baixo</h3>
      Para uma perda de integridade de baixo impacto, considera-se que as
      informações modificadas tem uma relevância baixo caso sejam divulgadas ou
      utilizadas. Os impactos financeiro, reputacional, operacional e
      regulatórios relacionados são baixos.

      <br />
      <br />
      <h3>Médio</h3>
      Para uma perda de integridade de médio impacto, considera-se que as
      informações modificadas tem uma relevância média caso sejam divulgadas ou
      utilizadas. Os impactos financeiro, reputacional, operacional e
      regulatórios relacionados são médios.

      <br />
      <br />
      <h3>Alto</h3>
      Para uma perda de integridade de alto impacto, considera-se que as
      informações modificadas tem uma relevância alta caso sejam divulgadas ou
      utilizadas. Os impactos financeiro, reputacional, operacional e
      regulatórios relacionados são altos.
      <br />
      <br />
    </Sidebar>
    <Sidebar
      v-model:visible="showInfoDisponibility"
      :baseZIndex="10000"
      position="right"
    >
      <h3>Baixo</h3>
      Quando informações de baixa relevância não estão disponíveis para uso ou
      divulgação.

      <br />
      <br />
      <h3>Médio</h3>
      Quando informações de média relevância não estão disponíveis para uso ou
      divulgação.

      <br />
      <br />
      <h3>Alto</h3>
      Quando informações de alta relevância não estão disponíveis para uso ou
      divulgação.
      <br />
      <br />
    </Sidebar>
    <div class="col-12">
      <div class="card">
        <h3 style="width: 100%; text-align: center">
          {{ company }}
        </h3>
        <h5 style="width: 100%; text-align: center">{{ responsible }}</h5>
        <br>
        <div class="card flex flex-wrap">
          <div class="flex flex-column">
            <h3>Operational Impact</h3>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                1 Hour:
              </p>
              <SelectButton
                v-model="selectedValueOperationalImpact0"
                :options="valuesOperationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                6 Hour:
              </p>
              <SelectButton
                v-model="selectedValueOperationalImpact1"
                :options="valuesOperationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                12 Hour:
              </p>
              <SelectButton
                v-model="selectedValueOperationalImpact2"
                :options="valuesOperationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Reputational Impact</h3>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                1 Hour:
              </p>
              <SelectButton
                v-model="selectedValueReputationalImpact0"
                :options="valuesReputationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                6 Hour:
              </p>
              <SelectButton
                v-model="selectedValueReputationalImpact1"
                :options="valuesReputationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                12 Hour:
              </p>
              <SelectButton
                v-model="selectedValueReputationalImpact2"
                :options="valuesReputationalImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Regulatory Impact</h3>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                1 Hour:
              </p>
              <SelectButton
                v-model="selectedValueRegulatoryImpact0"
                :options="valuesRegulatoryImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                6 Hour:
              </p>
              <SelectButton
                v-model="selectedValueRegulatoryImpact1"
                :options="valuesRegulatoryImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                12 Hour:
              </p>
              <SelectButton
                v-model="selectedValueRegulatoryImpact2"
                :options="valuesRegulatoryImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <!-- </div> -->
          </div>
        </div>

        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Financial Impact</h3>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                1 Hour:
              </p>
              <SelectButton
                v-model="selectedValueFinancialImpact0"
                :options="valuesFinancialImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                6 Hour:
              </p>
              <SelectButton
                v-model="selectedValueFinancialImpact1"
                :options="valuesFinancialImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
            <br />
            <div class="flex flex-row">
              <p style="font-weight: bold; width: 100px; padding-top: 10px">
                12 Hour:
              </p>
              <SelectButton
                v-model="selectedValueFinancialImpact2"
                :options="valuesFinancialImpact"
                optionLabel="name"
              >
                <template #option="slotProps">
                  <div
                    v-tooltip="slotProps.option.tooltip"
                    style="
                      height: 100%;
                      width: 100%;
                      position: absolute;
                      margin-left: -20px;
                    "
                  ></div>
                  <div style="font-weight: bold">
                    {{ slotProps.option.name }}
                  </div>
                </template>
              </SelectButton>
            </div>
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Workaround:</h3>
            <div
              style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              "
            >
              Existe algum workaround ( manual /automático) para contingenciar o
              processo em caso de indisponibilidade do serviço prestado
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <Dropdown
                v-model="hasWorkaround"
                :options="valuesWorkaround"
                optionLabel="name"
                placeholder="Select an Option"
                style="width: 200px"
              />
            </div>
            <div v-if="hasWorkaround != null">
              <div v-if="hasWorkaround.value">
                <h6 style="margin-top: 20px">Describe the workaround</h6>
                <Textarea v-model="workaroundText" rows="5" style="width: 100%" />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Loss Of Confidentiality:</h3>
            <div
              style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              "
            >
              Confidentiality is preventing someone from reading information
              they are not authorized to read.
            </div>
            <div class="flex flex-column">
              <div class="flex flex-row">
                <h5>Impact</h5>

                <button
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-text
                    p-button-rounded
                    p-button-plain
                  "
                  style="
                    height: 15px;
                    width: 15px;
                    margin-left: 5px;
                    padding: 10px;
                  "
                  type="button"
                  @click="showInfoConfidentiality = true"
                >
                  <span class="pi pi-info-circle p-button-icon"></span>
                </button>
              </div>
              <Dropdown
                v-model="selectedValueLossOfConfidentialityImpact"
                :options="valuesImpact"
                optionLabel="name"
                placeholder="Select an Impact"
                style="width: 200px"
              />
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <h5>Probability</h5>
              <Dropdown
                v-model="selectedValueLossOfConfidentialityProbability"
                :options="valuesProbability"
                optionLabel="name"
                placeholder="Select a Probability"
                style="width: 200px"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Loss Of Integrity:</h3>
            <div
              style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              "
            >
              The information sent must always remain in its original nature. It
              must not be changed during the transmission process.
            </div>
            <div class="flex flex-column">
              <div class="flex flex-row">
                <h5>Impact</h5>

                <button
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-text
                    p-button-rounded
                    p-button-plain
                  "
                  style="
                    height: 15px;
                    width: 15px;
                    margin-left: 5px;
                    padding: 10px;
                  "
                  type="button"
                  @click="showInfoIntegrity = true"
                >
                  <span class="pi pi-info-circle p-button-icon"></span>
                </button>
              </div>
              <Dropdown
                v-model="selectedValueLossOfIntegrityImpact"
                :options="valuesImpact"
                optionLabel="name"
                placeholder="Select an Impact"
                style="width: 200px"
              />
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <h5>Probability</h5>
              <Dropdown
                v-model="selectedValueLossOfIntegrityProbability"
                :options="valuesProbability"
                optionLabel="name"
                placeholder="Select a Probability"
                style="width: 200px"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <!-- <div class="card flex justify-content-center flex-wrap"> -->
          <div class="flex flex-column">
            <h3>Loss Of Disponibility:</h3>
            <div
              style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              "
            >
              The availability of information brings the idea of guaranteeing
              information at any time, without fail, to qualified and authorized
              people with the proper access.
            </div>
            <div class="flex flex-column">
              <div class="flex flex-row">
                <h5>Impact</h5>

                <button
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-text
                    p-button-rounded
                    p-button-plain
                  "
                  style="
                    height: 15px;
                    width: 15px;
                    margin-left: 5px;
                    padding: 10px;
                  "
                  type="button"
                  @click="showInfoDisponibility = true"
                >
                  <span class="pi pi-info-circle p-button-icon"></span>
                </button>
              </div>
              <Dropdown
                v-model="selectedValueLossOfDisponibilityImpact"
                :options="valuesImpact"
                optionLabel="name"
                placeholder="Select an Impact"
                style="width: 200px"
              />
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <h5>Probability</h5>
              <Dropdown
                v-model="selectedValueLossOfDisponibilityProbability"
                :options="valuesProbability"
                optionLabel="name"
                placeholder="Select a Probability"
                style="width: 200px"
              />
            </div>
          </div>
        </div>
        
          <div class="flex justify-content-center flex-wrap">
            <Button label="Submit" @click="sendData" />
            <!-- <Button label="Test" @click="test" /> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import TPMService from "../service/TPMService";
export default {
  data() {
    return {
      company: null,
      responsible: null,
      selectedValueOperationalImpact0: null,
      selectedValueOperationalImpact1: null,
      selectedValueOperationalImpact2: null,
      valuesOperationalImpact: [
        {
          name: "Low",
          value: 0,
          tooltip:
            "Light impact to the schedule of activities. Activities can be recovered in short term without affecting the normal flow of operation",
        },
        {
          name: "Moderate",
          value: 1,
          tooltip:
            "Moderate impact on the work schedule in such a way that the processes and/or activities must be rescheduled to be able to normalize the normal flow of operation",
        },
        {
          name: "High",
          value: 2,
          tooltip:
            "Significant delay in the execution of tasks and activities due to the accumulation of workload. It is necessary to formalize the situation and derive or request help from a third party to normalize the normal flow of operation",
        },
      ],
      selectedValueReputationalImpact0: null,
      selectedValueReputationalImpact1: null,
      selectedValueReputationalImpact2: null,
      valuesReputationalImpact: [
        {
          name: "Low",
          value: 0,
          tooltip: "It doesn't impair the image of the organization",
        },
        {
          name: "Moderate",
          value: 1,
          tooltip: "Insignificant impairment of the image",
        },
        {
          name: "High",
          value: 2,
          tooltip: "The impairment deserves attention and corrective actions",
        },
      ],
      selectedValueRegulatoryImpact0: null,
      selectedValueRegulatoryImpact1: null,
      selectedValueRegulatoryImpact2: null,
      valuesRegulatoryImpact: [
        {
          name: "Low",
          value: 0,
          tooltip: "There is no legal or regulatory impact",
        },
        {
          name: "Moderate",
          value: 1,
          tooltip:
            "It may generate a warning and/or monetary fine by external regulatory and supervisory bodies for non-compliance with laws and standards",
        },
        {
          name: "High",
          value: 2,
          tooltip:
            "It may generate internal sanctions for non-compliance with regulations",
        },
      ],
      selectedValueFinancialImpact0: null,
      selectedValueFinancialImpact1: null,
      selectedValueFinancialImpact2: null,
      valuesFinancialImpact: [
        {
          name: "Low",
          value: 0,
          tooltip: "It doesn't impair the image of the organization",
        },
        {
          name: "Moderate",
          value: 1,
          tooltip: "Insignificant impairment of the image",
        },
        {
          name: "High",
          value: 2,
          tooltip: "The impairment deserves attention and corrective actions",
        },
      ],
      hasWorkaround: null,
      workaroundText: null,
      valuesWorkaround: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      showInfoConfidentiality: false,
      showInfoIntegrity: false,
      showInfoDisponibility: false,
      selectedValueLossOfConfidentialityImpact: null,
      selectedValueLossOfConfidentialityProbability: null,
      selectedValueLossOfIntegrityImpact: null,
      selectedValueLossOfIntegrityProbability: null,
      selectedValueLossOfDisponibilityImpact: null,
      selectedValueLossOfDisponibilityProbability: null,
      valuesImpact: [
        {
          name: "Low",
          value: 0,
        },
        {
          name: "Medium",
          value: 1,
        },
        {
          name: "High",
          value: 2,
        },
      ],
      valuesProbability: [
        {
          name: "Unknown",
          value: 0,
        },
        {
          name: "Low",
          value: 1,
        },
        {
          name: "Medium",
          value: 2,
        },
        {
          name: "High",
          value: 3,
        },
      ],
    };
  },

  created() {
    console.log(this.$route.query);
    TPMService.getForm(this.$route.query.id).then((data) => {
      console.log(data);
      this.company = data[0].company;
      this.responsible = data[0].responsible;
    });
  },
  methods: {
    sendData(){
      TPMService.fillForm(this.$route.query.id, this.selectedValueOperationalImpact0.value, this.selectedValueOperationalImpact1.value, this.selectedValueOperationalImpact2.value, this.selectedValueReputationalImpact0.value, this.selectedValueReputationalImpact1.value, this.selectedValueReputationalImpact2.value, this.selectedValueRegulatoryImpact0.value, this.selectedValueRegulatoryImpact1.value, this.selectedValueRegulatoryImpact2.value, this.selectedValueFinancialImpact0.value, this.selectedValueFinancialImpact1.value, this.selectedValueFinancialImpact2.value, this.hasWorkaround.value, this.workaroundText, this.selectedValueLossOfConfidentialityImpact.value, this.selectedValueLossOfConfidentialityProbability.value, this.selectedValueLossOfIntegrityImpact.value, this.selectedValueLossOfIntegrityProbability.value, this.selectedValueLossOfDisponibilityImpact.value, this.selectedValueLossOfDisponibilityProbability.value).then((data) => {
        console.log(data)
      })
    }
  }
};
</script>

<style>
</style>